import { Link } from "react-router-dom";

import { Fragment, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import React from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import itavelToursCardCss from "./ItravelTourCard.module.scss";
function ItravelToursCard(props) {
  const [datesall, setDatesAll] = useState([]);

  useEffect(() => {
    if (props.dates) {
      const dataArray = props.dates
        .split(/\n\s*\n/)
        .map((date) => date.split(/\n/).filter(Boolean))
        .reduce((accumulator, dateBlock) => {
          let currentBlock = [];

          for (let i = 0; i < dateBlock.length; i++) {
            const line = dateBlock[i];

            if (line.includes("Залишилось 0 місць")) {
              // Если строка содержит "Залишилось 0 місць", пропускаем этот блок
              continue;
            }

            // Иначе добавляем строку к текущему блоку
            currentBlock.push(line);

            // Если это последняя строка блока, добавляем текущий блок
            if (i === dateBlock.length - 1 && currentBlock.length > 0) {
              accumulator.push(currentBlock);
            }
          }

          return accumulator;
        }, []);

      setDatesAll(dataArray);
    }
  }, [props.dates]);

  console.log(datesall);

  return (
    <div className={itavelToursCardCss.container}>
      <Link
        to={`/itravel/description_tour/tours/${props.id}`}
        className={itavelToursCardCss.link}
      >
        <div className={itavelToursCardCss.searchResultTour}>
          <img
            className={itavelToursCardCss.tour_photo}
            src={props.src}
            alt='Автобусний тур "Стамбульський експрес" з Одеси'
          />
          <div className={itavelToursCardCss.tour_info}>
            <h2 className={itavelToursCardCss.title}>{props.title}</h2>
            <div className={itavelToursCardCss.tour_info}>
              <div className={itavelToursCardCss.tour_price}>{props.price}</div>
              <div
                className={itavelToursCardCss.tour_description}
                dangerouslySetInnerHTML={{ __html: props.tourDescriptions }}
              ></div>
            </div>
          </div>
        </div>
      </Link>

      <Swiper
        className={itavelToursCardCss.swiperlistTour}
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        // direction="horizontal"
        navigation
        spaceBetween={3}
        slidesPerView={5}
        // slideClass="swiper_slide_cast"

        // pagination={{ clickable: true }}
        // scrollbar={{ draggable: true }}
        // speed={500}
        // initialSlide={0}
        // noSwiping={true}
        // noSwipingClass="swiper-no-swiping"
        breakpoints={{
          1024: { slidesPerView: 5, spaceBetween: 3 },
          768: { slidesPerView: 3, spaceBetween: 2 },
          480: { slidesPerView: 2, spaceBetween: 1 },
          320: { slidesPerView: 1, spaceBetween: 1 },
        }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log("slide change")}
      >
        {datesall.map((date, index) => (
          <SwiperSlide key={index} className={itavelToursCardCss.swiper_slide}>
            {date.map((item, innerIndex) => (
              <div key={innerIndex}>{item.trim()}</div>
            ))}
          </SwiperSlide>
        ))}
      </Swiper>
      <hr />
    </div>
  );
}

export default ItravelToursCard;
